const INTL_DOMAIN = 'desk.tencentcloud.com';

export const isInTestOrDev = ['dev', 'prod-test'].includes(
  import.meta.env.VITE_APP_MODE,
);

export const isInDev = ['dev'].includes(import.meta.env.VITE_APP_MODE);

export const isIntlSite = window.location.hostname.endsWith(INTL_DOMAIN);
// export const isIntlSite = true;

export const getLoginPath = () => {
  if (isInTestOrDev) {
    return `//dev.tccc.qcloud.com/login`;
  }
  if (isIntlSite) {
    return `//connect.tencentcloud.com/login`;
  }
  return `//tccc.qcloud.com/login`;
};

export const getIframeDomain = () => {
  if (isInTestOrDev) {
    return 'https://test.cccweb.pstn.avc.qcloud.com';
  }
  if (isIntlSite) {
    return 'https://connect.tencentcloud.com';
  }
  return 'https://tccc.qcloud.com';
};
